.header {
  padding: 33px 0 0;
  position: fixed;
  width: 100%;
  z-index: 999999999;
  transition: all 0.5s ease;
  top: 0;
  backdrop-filter: blur(8px);
  &.antiblured {
    backdrop-filter: blur(0px) !important;
  }
  &.psd {
    backdrop-filter: blur(0px) !important;
    &.fixed {
      backdrop-filter: blur(8px) !important;
    }
  }
  &.fixed {
    background-color: rgb(0 0 0 / 65%);
    backdrop-filter: blur(8px) !important;
    padding: 32px 0 !important;
    //padding: 32px 0 31px;
    &.white {
      top: -130px;
      .menu {
        .menu_item {
          
        }
      }
      &.fixed {
        box-shadow: 0 4px 8px rgb(0 0 0 / 10%) !important;
        .logo {
          top: 0px;
          img {
            width: 90px !important;
          }
        }
      }
    }
    .menu {
      .menu_item {
        
      }
    }
  }
  .logo {
    display: block;
    position: relative;
    top: 0px;
    cursor: pointer;
    text-align: center;
    transition: all 1s ease;
    opacity: 0;
    transform: scale(0.9);
    line-height: 1;
    user-select: none;
    &.plus {
      img {
        width: 110px !important;
      }
    }
    &.animated {
      opacity: 1;
      transform: scale(1);
    }
    * {

      user-select: none;
    }
    img {
      width: 108px;
      cursor: pointer;
      transition: all 0.3s ease;
      user-select: none;
      &:hover {
        transform: scale(1.07);
      }
    }
  }
  .menu {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: flex-start;
    width: 100%;
    line-height: 1;
    overflow: unset !important;
    &.right {
      align-items: flex-end;
      width: fit-content !important;
      margin: 0 0 0 auto !important;
    }
    .menu_items {
      display: flex;
      flex-direction: row;
      align-items: center;
      line-height: 1;
      overflow: unset !important;
      a {
        display: block;
        min-width: fit-content;
        margin-right: 72px;
        color: rgba(255, 255, 255, 1);
        font-size: 20px;
        letter-spacing: 0.5px;
        text-decoration: none;
        font-weight: 600;
        cursor: pointer;
        transition: all 1s ease;
        user-select: none;
        position: relative;
        line-height: 1;
        padding-top: 4px;
        opacity: 0;
        transform: scale(1);
        &::after {
          content: " ";
          width: 0%;
          position: absolute;
          height: 1px;
          background-color: #fff;
          bottom: 0px;
          left: 0px;
          transition: all 0.3s ease;
        }
        &:hover {
          color: #fff !important;
          &::after {
            width: 100%;
          }
        }
        &.bell {
          font-size: 26px !important;
          color: rgba(255, 255, 255, 0.5);
          transition: all 0.4s ease;
          span {
            &:nth-child(1) {
              display: block;
            }
            &:nth-child(2) {
              display: none;
            }
          }
          &.activated {
            span {
              &:nth-child(1) {
                display: none !important;
              }
              &:nth-child(2) {
                display: block !important;
              }
            }
          }
          &::after {
            content: none;
            color: rgba(255, 255, 255, 1);
          }
        }
        &.animated {
          opacity: 1;
          transform: scale(1);
        }
        &:hover {
          color: #fff;
        }
        &.active {
          color: #fff !important;
        }
        &:last-child {
          margin-right: 0px !important;
        }
      }
    }
  }
}

.mob_menu {
  display: block;
  opacity: 1;
  width: 100%;
  height: 100lvh;
  overflow: hidden;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(6px);
  z-index: 999999999999999;
  transition: all 0.3s ease;
  &.fixedd {
    .mm_w_logo {
      img {
        width: 80px !important;
      }
    }
    .mm_wrap {
      padding: 22px 15px !important;
    }
    .mm_w_close {
      top: 22px !important;
      right: 23px !important;
    }
  }
  .mm_w_lang {
    position: absolute;
    bottom: 80px;
  }
  .mm_wrap {
    padding: 27px 15px;
    .mm_w_close {
      position: absolute;
      top: 28px;
      right: 17.7px;
      color: #fff;
      z-index: 9999999999;
      font-size: 20px;
    }
    .mm_w_logo {
      margin-bottom: 32px;
      img {
        width: 90px;
      }
    }
    .mm_w_items {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 64px;
      a {
        display: block;
        color: rgba(255, 255, 255, 1);
        text-decoration: none;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin-bottom: 18px;
        cursor: pointer;
        &:hover {
          color: #fff;
        }
        &.active {
          color: #fff !important;
        }
      }
    }
  }
}

.hovered_icon {
  color: rgba(255, 255, 255, 0.5) !important;
  transition: all 0.3s ease;
  &:hover {
    color: #fff !important;
  }
}

@media screen and (max-width: 1280px) {
  .menu {
    .menu_items {
      a {
        margin-right: 50px !important;
        font-size: 16px !important;
        &:last-child {
          margin-right: 0px !important;
        }
      }
    }
  }
  .logo {
    img {
      &:hover {
        transform: unset !important;
      }
    }
  }
}

@media screen and (max-width: 774px) {
  .header {
    padding: 24px 0 !important;
    &.fixed {
      padding: 16px 0 27px !important;
      .logo {
        img {
          width: 90px !important;
          top: 3px;
        }
      }
    }
    .logo {
      text-align: center;
      top: 3px !important;
      img {
        width: 90px !important;
        position: relative;
        top: 0;
      }
    }
    .mobile_menu {
      width: 100%;
      text-align: right;
      transition: all 0.4s ease;
      button {
        background-color: transparent;
        border: none;
        color: #fff;
        font-size: 24px;
        height: 32px;
        position: relative;
        top: 0px;
        right: -6px;
        cursor: pointer;
        padding: 0 6px !important;
        img {
          width: 18px !important;
        }
      }
    }
  }

  .menu {
    &:not(.right) {
      a {
        margin-right: 45px !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .logo {
    text-align: left !important;
  }
}